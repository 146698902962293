@mixin width-wrapper {
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

@mixin hyphens {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

