@import '../../style/variables';

.step_pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $vodafone;
  font-weight: $bold;
  font-size: 14px;

  .button_prev,
  .button_next {
    line-height: 25px;
    height: 25px;
    cursor: pointer;
  }

  .button_prev:before,
  .button_next:after {
    content: '';
    display: block;
    width: 20px;
    height: 25px;

  }

  .button_prev:before {
    float: left;
    margin-right: 10px;
    background-image: url('/assets/icons/arrow-orange.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  .button_next:after {
    float: right;
    margin-left: 10px;
    transform: rotate(180deg);
    background-image: url('/assets/icons/arrow-orange.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  .button_numbers {
    background-image: url('/assets/icons/pixel-petrol.gif');
    background-repeat: repeat-x;
    background-position: center;


    .button {
      border: none;
      width: 32px;
      height: 32px;
      margin: 0 25px;
      border-radius: $borderRadius;
      background: #fff;
      font-family: $vodafone;
      font-weight: $medium;
      font-size: 14px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background: $primary;
        color: #fff;
      }
    }
  }
}
