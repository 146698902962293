@import "variables";

:global {
    .isDisabled {
        & .uppy-Root {
            * {
                background-color: $gray-light !important;
                color: #9C9CAA !important;
                cursor: inherit !important;
                pointer-events: none !important;
            }
        }
        
        & .uppy-Dashboard-Item-actionWrapper {
            display: none !important;
        }
        
        > p {
            display: none;
        }
    }
    
    .uppy {
        &-Root {
            margin-top: 10px !important;
            border: 1px dashed $primary !important;
            border-radius: 5px !important;
            font-family: $roboto !important;
        }
        
        &-Dashboard {
            &-inner {
                width: 100% !important;
                max-height: 250px !important;
                overflow: hidden !important;
                border: none !important;
                
                &Wrap {
                    overflow: visible !important;
                }
            }
            
            &-files {
                overflow: visible !important;
            }
            
            &-AddFiles {
                border: none !important;
                
                &-title {
                    font-size: 14px !important;
                    margin: 0 !important;
                }
            }
            
            &-Item {
                &-name {
                    color: $black !important;
                }
                
                &-statusSize {
                    color: $gray-dark !important;
                }
                
                &-action {
                    cursor: pointer !important;
                    
                    svg {
                        > path {
                            &:first-child {
                                fill: $black !important;
                            }
                        }
                    }
                }
                
                &-previewInnerWrap {
                    overflow: visible;
                    background-color: $primary !important;
                    box-shadow: 0 0 10px 0 rgba(42, 42, 42, 0.1) !important;
                }
                
                &-previewImg {
                    position: absolute;
                    width: calc(100% + 2px) !important;
                    height: calc(100% + 2px) !important;
                    border-radius: 3px !important;
                    left: -1px !important;
                    top: -1px !important;
                    
                }
                
                &-previewIcon {
                    width: 70% !important;
                    height: 100% !important;
                    color: $white !important;
                    
                    svg > * {
                        fill: $white !important;
                    }
                    
                    &Bg {
                        filter: none !important;
                        
                        & > * {
                            fill: $primary !important;
                        }
                    }
                }
            }
            
            &-browse {
                color: $primary !important;
                
                &:hover,
                &:focus {
                    cursor: pointer !important;
                    border-bottom: 1px solid $primary !important;
                }
            }
            
            &-dropFilesHereHint {
                border: 1px dashed $gray-light !important;
            }
            
            &Content-bar {
                display: none !important;
            }
            
            &--isDraggingOver {
                .uppy-Dashboard-dropFilesHereHint {
                    background-image: none !important;
                    padding-top: 0 !important;
                    top: 0 !important;
                    left: 0 !important;
                    right: auto !important;
                    bottom: auto !important;
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
        
        &-StatusBar {
            &-progress,
            &-actions,
            &.is-waiting {
                display: none !important;
            }
        }
        
        &-Informer {
            top: 0;
            height: 100%;
            padding-bottom: 5px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            pointer-events: none;
            &[aria-hidden=true] {
                transform: translateY(0);
            }
            
            p {
                font-size: 13px !important;
                background-color: rgba(156,156,156,0.6) !important;
                border-radius: $borderRadius !important;
                line-height: 1.2em !important;
                padding: 5px;
                max-width: calc(100% - 10px);
            }
        }
    }
    
    .singleUpload {
        .uppy {
            &-Dashboard {
                &-files {
                    padding: 0 !important;
                    
                    div[role=presentation] {
                        height: 100% !important;
                    }
                }
                
                &-Item {
                    height: 100% !important;
                    border: 0 !important;
                    
                    &-preview {
                        width: 80px !important;
                        max-height: 100% !important;
                        height: 80px !important;
                    }
                }
            }
        }
    }
}
