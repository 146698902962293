//colors
$primary-light: #DFEDEF;
$primary: #e60101;
$primary-hover: #c40000;
$orange: #FF6E00;
$orange-light: #ffd6b6;
$gray-white: #FAFAFA;
$gray-light: #EEEEEE;
$gray-medium-light: #C0C0C0;
$gray-medium: #D2D2D2;
$gray-dark: #9C9C9C;
$white: #FFFFFF;
$black: #292929;

//fonts
$roboto: 'Roboto', sans-serif;
$robotoSlab: 'Roboto Slab', serif;
$vodafone: 'Vodafone', sans-serif;

//font-weights
$regular: 400;
$medium: 500;
$bold: 700;

//border-radius
$borderRadius: 5px;

//branding
$playfairDisplay: 'Playfair Display', serif;
$barlow: 'Barlow', sans-serif;

//page wrapper
$maxWidth: 1240px;
