@import "../../style/variables";
@import "../../style/mixins";

@import 'settings.module.scss';

img {
    display: block;
    width: 100%;
    height: auto;
}

.linkboard {
    &-container {
        height: 100%;
        cursor: pointer;
        scrollbar-width: none;
    
        &::-webkit-scrollbar {
            display: none !important;
        }
        
        &:hover {
            .options-container {
                opacity: 1;
                transition: opacity linear 0.2s;
                
                .options-button {
                    pointer-events: all;
                }
            }
        }
        
        &.preview {
            .banner-container {
                background-image: none;
            }
        }
    }
}

$bannerHeight: 130px;
$spacer: 15px;

.banner {
    &-container {
        position: relative;
        overflow: hidden;
        height: $bannerHeight;
        background-image: url("../../assets/placeholder/banner.svg");
        background-size: cover;
        background-position: center;
        
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        + .content-container {
            padding-top: calc(#{$spacer} * 2);
        }
    }
}

.banner {
    &-container-empty {
        position: relative;
        overflow: hidden;
        height: $bannerHeight;
    }
}

$logoWidth: 100px;

.logo {
    &-container {
        position: relative;
        display: inline-block;
        @include aspect-ratio(1, 1, true);
        background-color: white;
        width: $logoWidth;
        border-radius: 50%;
        box-shadow: 0 0 20px transparentize($color: #000000, $amount: 0.9);
        margin-top: calc((-#{$logoWidth} / 2) - (#{$spacer} * 2));
        margin-bottom: $spacer;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        
        + .description,
        + .headline {
            margin-top: 0;
        }
        
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

.content {
    &-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0 10px 10px 10px;
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
        min-height: calc(100% - #{$bannerHeight});
    }
}

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-weight: $medium;
    font-size: 14px;
    height: 50px;
    width: 100%;
    padding: 5px 50px;
    margin: 5px auto;
    color: white;
    text-decoration: none;
    position: relative;
    
    * {
        position: absolute;
        left: 10px;
    }
    
    span:not(.empty) {
        font-size: 18px;
        padding: 5px;
        border-radius: 100%;
        position: absolute;
        left: 10px;
    }
    
    .image-container {
        width: 40px;
        height: 27px;
        overflow: hidden;
        
        img {
            width: auto;
            max-width: 100%;
            height: 100%;
            object-fit: contain;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.description,
.headline {
    font-size: 14px;
    white-space: pre-wrap;
    @include hyphens;
    
    + .button {
        margin-top: $spacer;
    }
}

.description {
    font-weight: $regular;
    line-height: 1.5em;
    margin-bottom: 0;
}

.headline {
    font-weight: $bold;
    margin: 0 0 5px 0;
}

.policyUrl {
    margin-top: -1px;
    text-align: center;
    width: 100%;
    display: block;
    font-size: 12px;
    font-weight: 100;
    text-decoration: underline !important;
    padding: $spacer;
}

.product {
    text-align: center;
    
    &_container {
        margin: $spacer 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        
        &.grid {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 10px;
        }
        
        + a {
            margin-top: $spacer;
        }
    }
    
    .image-container {
        width: 100%;
        display: flex;
        position: relative;
        overflow: hidden;
        
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
        }
        
        &:after {
            content: '';
            padding-bottom: 100%;
            position: relative;
            display: inline-block;;
        }
    }
    
    h6.headline {
        margin: 5px 0 0 0;
        font-size: 14px;
        line-height: 1em;
    }
    
    p.price {
        margin: 2px 0 0 0;
        line-height: 1em;
        font-size: 10px;
    }
}

.icon {
    display: inline-block;
    font-size: 18px;
    font-weight: $bold;
    line-height: 1em;
    
    &-container {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    
    &-outer {
        border-radius: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin: 4px;
    }
}

.options {
    &-container {
        display: flex;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        background: rgba(255, 255, 255, 0.8);
        width: calc(100% + (#{$spacer} * 2));
        bottom: -#{$spacer};
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0 0 35px 35px;
        padding: 40px 20px 25px 20px;
        transition: opacity linear 0.2s;
    }
    
    &-button {
        background-color: $black;
        border-radius: 100%;
        color: $white;
        width: 50px;
        height: 50px;
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 7px;
        transition: background-color linear 0.2s;
        
        &:hover {
            background-color: #1d1d1d;
            transition: background-color linear 0.2s;
        }
        
        img {
            width: 20px;
        }
    }
}


