$tagBackground: #2C8693;
:global {
  .tagContainer {
    display: flex;
    flex-wrap: wrap;
  
    .tagsCell & {
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
    }
  
    .tag {
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: $tagBackground;
      height: 30px;
      line-height: 30px;
      padding: 0px 8px 0px 8px;
      color: white;
      border-radius: 2px;
      +.hidden {
        display: none;
      }
    }
  }
}
