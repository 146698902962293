@import "variables";

:global {
    label {
        margin-bottom: 5px;
        font-family: $vodafone;
        font-weight: $medium;
        font-size: 14px;
    }

    .link--beacon:not(.button--small):not(.header__link) {
        color: $black;
        display: inline-flex;
        align-items: center;
        line-height: 1.4em !important;
        font-size: inherit !important;
        font-weight: inherit !important;

        &::after {
            content: '';
            position: relative;
            display: inline-block;
            width: 1.6em;
            height: .8em;
            margin-left: .4em;
            background-image: url("../assets/icons/beacon-icon.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .button--small {
        background-color: $primary;
        color: $white !important;
        border-radius: 3px;
        font-size: 14px !important;
        padding: 5px 10px;
        font-weight: $medium;
    }




    .width_25 {
        width: calc(25% - 15px);
    }

    .width_33 {
        width: calc(33% - 11px);
    }

    .width_50 {
        width: calc(50% - 10px);
    }

    .width_66 {
        width: calc(66% - 3px);
    }

    .width_75 {
        width: calc(75% - 5px);
    }

    .width_100 {
        width: 100%;
    }

    .no-wrap {
        white-space: nowrap;
    }

    .no-margin {
        margin: 0 !important;
    }

    .button_wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .errormessage {
        color: $black;
        text-align: right;
        font-size: 10px;
        font-weight: bold;
    }

    #next {
        background: $primary;
        color: #fff;
    }

    #prev {
        background: #fff;
        color: $black;
    }

    .selectbox__menu {
        background: #fff !important;
        z-index: 10 !important;
        color: $primary !important;
        overflow: hidden !important;
    }

    @mixin card {
        width: 4em;
        height: 2.5em;
        padding: 6px;
        border-radius: 5px;
        background: #fff;
        display: flex;
        align-items: center;
    }

    .card {
        @include card;

        &_left {
            @include card;
            padding-left: 0;
            width: 3.5em;
        }
    }

    #triggerElement {
        > .fixed {
            position: fixed;
            top: 130px;

            @media (max-height: 700px) {
                top: 110px;
            }
        }
    }

    #payPal,
    #creditCard {
        &.active {
            background-color: $primary !important;
            transition: background-color 0.3s ease-in-out;
            color: #fff !important;

        }
    }

    #payPalBox,
    #creditCardBox {
        display: none;

        &.active {
            display: block;

        }
    }

    #creditCard.active {
        border-radius: 4px 0 0 4px;
    }

    #payPal.active {
        border-radius: 0 4px 4px 0;
    }

    //Sketch picker

    .sketch-picker {
        border-radius: $borderRadius !important;
        box-shadow: 0 0 20px 0 rgba(42, 42, 42, 0.2) !important;

        > div:first-child {
            padding-bottom: 50% !important;
        }

        &:after {
            content: '';
            position: absolute;
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: $white;
            left: 20px;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
        }

        .flexbox-fix {
            input {
                width: 100% !important;
                border-radius: $borderRadius !important;
                height: 30px !important;
                text-align: center !important;
            }

            &:nth-child(2) {
                height: 20px !important;
                margin-bottom: 6px;

                .hue-horizontal {
                    div {
                        top: -1px !important;
                    }
                }

                * {
                    height: 100% !important;
                }

                > div:nth-child(2) {
                    height: 12px !important;
                    border: 1px solid $gray-light !important;
                    border-radius: 3px !important;

                    * {
                        box-shadow: none !important;
                    }
                }
            }

            &:nth-child(3) {
                display: none !important;
            }

            &:nth-child(4) {
                width: 100% !important;
                margin: auto !important;
                padding: 10px 0 0 0 !important;

                div {
                    width: 20px !important;
                    height: 20px !important;
                    box-shadow: 0 0 0 1px $gray-light !important;
                    border-radius: 3px !important;
                }
            }
        }
    }


    .pre-line {
        white-space: pre-wrap;
    }

    .downloadCsv {
        cursor: pointer;

        img {
            width: 16px;
            height: auto;
        }
    }

    .information-bar {
        margin: 0;
    }

    .tooltip {
        line-height: normal;
        opacity: 0;
        padding: .3em;
        pointer-events: none;
        position: fixed;
        text-align: center;
        transform: translate(-50%, 0);
        transition: all .1s ease;
        min-width: 80px;
        border-radius: 5px;
        background: $primary;


        &-label,
        &-value {
            color: #FFF;
        }

        &-label {
            font-size: 12px;
        }

        &-value {
            font-size: 18px;
        }

        &-caret {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) translateY(80%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9.2px 9px 0 9px;
            border-color: $primary transparent transparent transparent;
        }
    }

    .disabled,
    .inactive {
        opacity: 0.5;
        cursor: default !important;
    }

    .paypal_button {
        width: 100%;
        height: 55px;
    }

    /////ERROR MESSAGE/////

    .errorMessage {
        position: relative;

        &:after {
            font-size: 12px;
            position: absolute !important;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: right;
            color: $orange;
        }

        &.url:after {
            content: 'Please enter a valid URL.';
        }

        &.email:after {
            content: 'Please enter a valid email.';
        }

        &.text:after {
            content: 'Please fill out this field.';
        }

        &.slash-tag:after {
            content: 'The Slash-Tag must not contain any whitespace.';
        }

        &.number:after {
            content: 'Please enter a valid number.';
        }

        &.file:after {
            content: 'Please select an image.';
        }

        &.multi-select:after {
            content: 'Please select a group.';
        }

        &.file {
            &:after {
                right: 0;
                width: auto;
                bottom: -18px;
            }

            p.infoMessage {
                display: none;
            }

            .error:after {
                bottom: 35px;
                right: 10px;
                transform: translateY(100%);
            }
        }
    }

    .error {
        background-image: url('/icons/warning.svg') !important;
        background-position: right 15px center;
        background-repeat: no-repeat;
        background-size: 15px;
        position: relative;

        &:after {
            content: '';
            width: 18px;
            height: 24px;
            background-image: url('/icons/warning.svg') !important;
            background-repeat: no-repeat;
            background-size: 15px;
            background-position: center;
            display: inline-block;
            right: 65px;
            bottom: 15px;
            position: absolute;
        }
    }

    form {
        .error::after {
            right: 15px;
        }
    }

    .accordionItem:not(.secondary) label.error,
    .accordionItemBox label.error {
        background-image: none !important;

        &:after,
        &:before {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            display: inline-block;
            background-repeat: no-repeat ;
        }

        &:after {
            content: '';
            width: 16px;
            height: 21px;
            background-image: url('/icons/warning.svg') !important;
            transform: translateY(-50%) rotate(0deg) !important;
            right: 0;
            background-size: 15px;
        }
    }

    .accordionItemBox label.error::after {
        right: 30px !important;
    }

    .error[data-type="multi-select"] {
        &:after {
            display: none;
        }

        .selectbox__value-container {
            overflow-y: auto;

            &:before {
                content: '';
                width: 18px;
                height: 100%;
                background-image: url('/icons/warning.svg') !important;
                background-repeat: no-repeat;
                background-size: 15px;
                background-position: center;
                display: inline-block;
                right: 15px;
                bottom: 0;
                position: absolute;
            }
        }
    }

    .selectboxWrapper {
        position: relative;
        margin-bottom: 20px;
    }


    table {
        text-align: left;

        tr {
            > th {
                padding-right: 30px;
            }
        }
    }

}
