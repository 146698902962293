
@import '../../../style/variables';


h1 {
    max-width: 620px;
}

p {
    max-width: 770px;
}

// ul {
//     list-style: none;
    
// }
// ul li{
//     height: 42px;
//     line-height: 42px;
// }
// ul li::before {
//     content: "\2022";
//     color: $primary;
//     font-weight: bold;
//     font-size: 32px;
//     display: inline-block;
//     width: 1em;
//     margin-left: -1em;
// }