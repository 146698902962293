/**
 * Colors
 **/

 $primary: #111;
 $secondary: #222;
 $tertiary: #333;
 $quatiary: #444;
 
 $highlight: #0f0;
 $error: #f00;
 
 /**
  * Math
  **/
 
 $pi: 3.14159265359;
 
 /**
 * Mixins
 **/
 
 $breakpoints: (
   "mobile": 400px,
   "mobile-wide": 480px,
   "phablet": 560px,
   "tablet-small": 640px,
   "tablet": 768px,
   "tablet-wide": 1024px,
   "desktop": 1248px,
   "desktop-wide": 1440px
 );
 
 @mixin mq($width, $type: min) {
   @if map_has_key($breakpoints, $width) {
     $width: map_get($breakpoints, $width);
     @if $type == max {
       $width: $width - 1px;
     }
     @media (#{$type}-width: $width) {
       @content;
     }
   }
 }
 
 @mixin aspect-ratio($x, $y, $pseudo: false) {
   $padding: unquote(($y / $x) * 100 + "%");
   @if $pseudo {
     &:before {
       @include pseudo($pos: relative);
       width: 100%;
       padding-top: $padding;
     }
   } @else {
     padding-top: $padding;
   }
 }
 
 @mixin pseudo($display: block, $pos: absolute, $content: "\A") {
   content: $content;
   display: $display;
   position: $pos;
 }
 
 @mixin css-triangle(
   $color,
   $direction,
   $size: 6px,
   $position: absolute,
   $round: false
 ) {
   @include pseudo($pos: $position);
   width: 0;
   height: 0;
   @if $round {
     border-radius: 3px;
   }
   @if $direction == down {
     border-left: $size solid transparent;
     border-right: $size solid transparent;
     border-top: $size solid $color;
     margin-top: 0 - round($size / 2.5);
   } @else if $direction == up {
     border-left: $size solid transparent;
     border-right: $size solid transparent;
     border-bottom: $size solid $color;
     margin-bottom: 0 - round($size / 2.5);
   } @else if $direction == right {
     border-top: $size solid transparent;
     border-bottom: $size solid transparent;
     border-left: $size solid $color;
     margin-right: -$size;
   } @else if $direction == left {
     border-top: $size solid transparent;
     border-bottom: $size solid transparent;
     border-right: $size solid $color;
     margin-left: -$size;
   }
 }
 
 /**
  * Fontface / Fonts
  **/
 
 @mixin font-face($name, $file) {
   @font-face {
     font-family: "#{$name}";
     src: url("../fonts/#{$file}.eot");
     src: url("../fonts/#{$file}.eot?#iefix") format("embedded-opentype"),
       url("../fonts/#{$file}.woff") format("woff"),
       url("../fonts/#{$file}.ttf") format("truetype"),
       url("../fonts/#{$file}.svg?#webfont") format("svg");
   }
 }
 
 /**
  * Abstract helpers
  **/
 