@font-face {
    font-family: 'Vodafone';
    src: url('../assets/fonts/vodafone/WOFF2/Vodafone_Lt_W.woff2') format('woff2'),
         url('../assets/fonts/vodafone/WOFF/Vodafone_Lt_W.woff') format('woff'),
         url('../assets/fonts/vodafone/EOT/Vodafone_Lt_W.eot') format('eot');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Vodafone';
    src: url('../assets/fonts/vodafone/WOFF2/Vodafone_Bk_W.woff2') format('woff2'),
    url('../assets/fonts/vodafone/WOFF/Vodafone_Bk_W.woff') format('woff'),
    url('../assets/fonts/vodafone/EOT/Vodafone_Bk_W.eot') format('eot');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Vodafone';
    src: url('../assets/fonts/vodafone/WOFF2/Vodafone_Rg_W.woff2') format('woff2'),
    url('../assets/fonts/vodafone/WOFF/Vodafone_Rg_W.woff') format('woff'),
    url('../assets/fonts/vodafone/EOT/Vodafone_Rg_W.eot') format('eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Vodafone';
    src: url('../assets/fonts/vodafone/WOFF2/Vodafone_Md_W.woff2') format('woff2'),
    url('../assets/fonts/vodafone/WOFF/Vodafone_Md_W.woff') format('woff'),
    url('../assets/fonts/vodafone/EOT/Vodafone_Md_W.eot') format('eot');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Vodafone';
    src: url('../assets/fonts/vodafone/WOFF2/Vodafone_SBd_W.woff2') format('woff2'),
    url('../assets/fonts/vodafone/WOFF/Vodafone_SBd_W.woff') format('woff'),
    url('../assets/fonts/vodafone/EOT/Vodafone_SBd_W.eot') format('eot');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Vodafone';
    src: url('../assets/fonts/vodafone/WOFF2/Vodafone_Bd_W.woff2') format('woff2'),
    url('../assets/fonts/vodafone/WOFF/Vodafone_Bd_W.woff') format('woff'),
    url('../assets/fonts/vodafone/EOT/Vodafone_Bd_W.eot') format('eot');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Vodafone';
    src: url('../assets/fonts/vodafone/WOFF2/Vodafone_XBd_W.woff2') format('woff2'),
    url('../assets/fonts/vodafone/WOFF/Vodafone_XBd_W.woff') format('woff'),
    url('../assets/fonts/vodafone/EOT/Vodafone_XBd_W.eot') format('eot');
    font-weight: 900;
    font-style: normal;
}


