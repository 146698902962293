$accentColor: rgb(44, 134, 147);
$selectionRadius: 5px;
:global {
  .DayPicker {
    outline: none !important;
  
    width: 390px;
    margin: 0 !important;
    font-size: 13px;

    &-NavButton {
      &:focus,
      &:active {
        outline: none !important;
      }
      // background-size: 80%;
  
      &--prev {
        background-image: url('/icons/arrow-calendar.svg') !important;
      }
  
      &--next {
        background-image: url('/icons/arrow-calendar.svg') !important;
        transform: rotate(180deg);
      }
    }
  
    &:not(&--interactionDisabled)
    &-Day:not(&-Day--disabled):not(&-Day--selected):not(&-Day--outside):hover {
      background-color: lighten(grey, 40%) !important;
    }
  
    .DayPicker-Day--today:not(.DayPicker-Day--outside):not(.DayPicker-Day--highlighted) {
      color: $black !important;
      font-weight: 700 !important;
      border-bottom: 2px solid #FF7000 !important;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
    .DayPicker-Day--highlighted:not(.DayPicker-Day--outside) {
      background-color: $accentColor !important;
      color: #FFF !important;
   

      
  }
  }
  
  #calendarWrapper {
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  
    &.active {
      display: block;
      opacity: 1;
      transform: translateY(10px);
    }
  }

  .DayPicker-Day {
    padding: 6px 18px;
    border-radius: 0% !important;
  }

  .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){
    background-color: #F4F4F4 !important;
    color: black !important;
    border-radius: 0% !important;
  }
  
  .DayPicker-Day--start {
    border-top-left-radius: $selectionRadius !important;
    border-bottom-left-radius: $selectionRadius !important;
  }
  .DayPicker-Day--end {
    border-top-right-radius: $selectionRadius !important;
    border-bottom-right-radius: $selectionRadius !important;
  }
}
