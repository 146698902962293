@import "../../style/variables";

.overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh + 20px);
}

.popup {
    position: relative;
    background: #fff;
    width: 100%;
    max-width: 750px;
    padding: 50px;
    border-radius: $borderRadius;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    
    
    .content_wrapper {
        max-width: 620px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        h2 {
            margin-top: 0;
            font-size: 36px;
        }
    }
    
    .warning {
        background: #ffe4d3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: $borderRadius;
        text-align: left;
        min-width: 360px;
        padding: 10px 20px 10px 0;
        
        img {
            width: 20px;
            margin: 0 20px;
        }
        
        p {
            font-size: 14px;
        }
    }
    
    label {
        font-size: 16px;
        margin: 40px 0 20px 0;
        font-weight: $regular;
    }
    
    .button_wrapper {
        display: flex;
        max-width: 450px;
        justify-content: center;
        margin: auto;
        width: 100%;
    }
    
    .cancel {
        position: absolute;
        width: 25px;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
    
    p {
        margin: 0;
        
        + .button_wrapper {
            margin-top: 30px;
        }
    }
    
    form {
        max-width: 510px;
    }
    
    .paypal_button_height {
        height: 55px;
    }
    
    button {
        margin: auto;
        align-self: center;

        &:first-child {
            width: 40%;
        }
        
        &:last-child {
            width: 60%;
        }
    }
}
