@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&family=Roboto:wght@400;500;700&display=swap');

:global {
    h1, h2, h3, h4, h5, h6 {
        line-height: 1.4em;
        margin: 30px 0 20px 0;

        &.primary {color: $primary}
        &.center {
            text-align: center;
            width: 100%;
            margin: auto;
        }

        + h1,
        + h2,
        + h3,
        + h4,
        + h5,
        + h6,
        + p {
            margin-top: 0;
        }

    }

    h1, h2, h3, h4 {
        font-family: $vodafone;
        font-weight: $bold;
        color: $black;
    }

    h1 {
        font-size: 40px;
        width: 100%;

        + div {
            margin-top: 40px !important;
            padding-top: 0 !important;
        }
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 20px;
    }

    h4, h5 {
        font-size: 18px;
        font-weight: $medium;
    }

    h6 {
        font-size: 18px;
        line-height: 1.6em;
        font-weight: $bold;
        margin: 0;
    }

    p {
        font-size: 16px;
        line-height: 1.8em;
    }

    a {
        color: $primary;
        font-weight: $bold;
        cursor: pointer;
        text-decoration: none;
    }

    *::selection {
        background: rgba(49,134,146,.2)!important;
        text-shadow: none!important;
    }

    button {
        -webkit-appearance: none;
        cursor: pointer;

        &:focus,
        &:active {
            outline: none;
        }
    }

    .robotoSlab {
        font-family: $robotoSlab !important;
    }

}
