@import './_variables';
@import './_fonts';
@import './_mixins';
@import './_normalize';
@import './react-table-overwrite';
@import './forms';
@import './calendar';
@import './tags';
@import './uppy';
@import './socion';
@import './vodafone';

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: $vodafone;
    font-weight: normal;
    color: $black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

:global {
    #app {
        min-height: 100vh;
        min-width: 1130px;
        margin: auto;
    }

    .content {
        min-height: 100vh;
    }

    .width-wrapper {
        @include width-wrapper;
    }

    .overlay {
        position: absolute;
        z-index: 1;
        background: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
    }

    //helpscout button
    .BeaconFabButtonFrame {
        opacity: 0 !important;
        pointer-events: none !important;
    }
}
